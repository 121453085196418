import app from 'core/application'
import _ from 'lodash'
import Marty from 'marty'
import Im from 'shared-js/immutable'
import EnrollmentGroupsState from 'state/enrollment-groups'
import TeamsState from 'state/teams'
import UsersState from 'state/users'
import { getIdFromApiUrl } from 'utilities/generic'

const autoDispatch = Marty.autoDispatch
const NO_TEAM_ID = -1
const ENROLLMENT_GROUP_ENDPOINT = 'enrollment_groups'
const TEAM_ENDPOINT = 'learner_groups'
const ENTITY_MAP = {
  ENROLLMENT_GROUP_ENDPOINT: 'enrollmentGroups',
  TEAM_ENDPOINT: 'teams',
}

const Constants = Marty.createConstants([
  'MANY_USER_SELECTION_TOGGLE_USER_SELECTED',
  'MANY_USER_SELECTION_TOGGLE_TEAM_SELECTED',
  'MANY_USER_SELECTION_TOGGLE_ENROLLMENT_GROUP_SELECTED',
  'MANY_USER_SELECTION_SET_TEAM_SELECTED',
  'MANY_USER_SELECTION_SET_ENROLLMENT_GROUP_SELECTED',
  'MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_USERS',
  'MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_TEAMS',
  'MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_ENROLLMENT_GROUPS',
  'SET_USER_SEARCH',
])

const ManyUserSelectionActionCreators = Marty.createActionCreators({
  id: 'ManyUserSelectionActionCreators',
  toggleUserSelected: autoDispatch(Constants.MANY_USER_SELECTION_TOGGLE_USER_SELECTED),
  toggleTeamSelected: autoDispatch(Constants.MANY_USER_SELECTION_TOGGLE_TEAM_SELECTED),
  toggleEnrollmentGroupSelected: autoDispatch(
    Constants.MANY_USER_SELECTION_TOGGLE_ENROLLMENT_GROUP_SELECTED
  ),
  setTeamSelected: autoDispatch(Constants.MANY_USER_SELECTION_SET_TEAM_SELECTED),
  setEnrollmentGroupSelected: autoDispatch(
    Constants.MANY_USER_SELECTION_SET_ENROLLMENT_GROUP_SELECTED
  ),
  setSelectedForManyUsers: autoDispatch(Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_USERS),
  setSelectedForManyTeams: autoDispatch(Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_TEAMS),
  setSelectedForManyEnrollmentGroups: autoDispatch(
    Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_ENROLLMENT_GROUPS
  ),
  setUserSearch: autoDispatch(Constants.SET_USER_SEARCH),
})

const ManyUserSelectionStore = Marty.createStore({
  id: 'ManyUserSelectionStore',
  handlers: {
    onToggleUser: Constants.MANY_USER_SELECTION_TOGGLE_USER_SELECTED,
    onToggleTeam: Constants.MANY_USER_SELECTION_TOGGLE_TEAM_SELECTED,
    onToggleEnrollmentGroup: Constants.MANY_USER_SELECTION_TOGGLE_ENROLLMENT_GROUP_SELECTED,
    onSetTeamSelected: Constants.MANY_USER_SELECTION_SET_TEAM_SELECTED,
    onSetEnrollmentGroupSelected: Constants.MANY_USER_SELECTION_SET_ENROLLMENT_GROUP_SELECTED,
    onSetSelectedForManyUsers: Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_USERS,
    onSetSelectedForManyTeams: Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_TEAMS,
    onSetSelectedForManyEnrollmentGroups:
      Constants.MANY_USER_SELECTION_SET_SELECTED_FOR_MANY_ENROLLMENT_GROUPS,
    onSetUserSearch: Constants.SET_USER_SEARCH,
  },
  getInitialState() {
    return {
      selectedUsers: Im.freeze({}),
      selectedTeams: Im.freeze({}),
      selectedEnrollmentGroups: Im.freeze({}),
      teams: Im.freeze({}),
      enrollmentGroups: Im.freeze({}),
      userSearch: '',
      currentUser: Im.freeze({}),
    }
  },
  resetState(currentUser) {
    const teams = this.state.teams
    const enrollmentGroups = this.state.enrollmentGroups
    this.state = this.getInitialState()
    // Keep teams value. Otherwise there will be errors if
    // enrollment modal is opened twice without changing page,
    // as state is reset after teams value gets set when
    // teams are available in local memory.
    this.state.teams = teams
    this.state.enrollmentGroups = enrollmentGroups
    this.state.currentUser = currentUser
  },
  _addOrRemove(map, key, val) {
    if (map[key]) {
      return Im.delete(map, key)
    }
    return Im.set(map, key, val)
  },
  onToggleUser(user) {
    this.state.selectedUsers = this._addOrRemove(this.state.selectedUsers, user.url, user)
    this.hasChanged()
  },
  onToggleTeam(team) {
    const storedTeam = this.state.teams[team.id]
    if (!storedTeam.__users) {
      this.state.selectedTeams = this._addOrRemove(this.state.selectedTeams, team.url, true)
    } else {
      // Remove team from selectedTeams, as now selection state for team is determined by
      // it's users
      this.state.selectedTeams = Im.delete(this.state.selectedTeams, team.url)
      const users = storedTeam.__users
      this.selectUsersFromEntity(users)
    }
    this.hasChanged()
  },
  getEntityTypeFromURL(url) {
    if (url.indexOf(ENROLLMENT_GROUP_ENDPOINT) > -1) {
      return ENTITY_MAP.ENROLLMENT_GROUP_ENDPOINT
    }
    if (url.indexOf(TEAM_ENDPOINT) > -1) return ENTITY_MAP.TEAM_ENDPOINT
  },
  onToggleEntity(entity) {
    const entityType = this.getEntityTypeFromURL(entity.url)
  },
  selectUsersFromEntity(users) {
    let anyNotSelected = false
    users.forEach((user) => {
      if (anyNotSelected) return
      if (!this.isSelectedUser(user)) {
        anyNotSelected = true
      }
    })
    if (anyNotSelected) {
      // Select all
      this.onSetSelectedForManyUsers(users, true)
    } else {
      // Select none
      this.onSetSelectedForManyUsers(users, false)
    }
  },
  onToggleEnrollmentGroup(enrollmentGroup) {
    this.onToggleEntity(enrollmentGroup)
    const storedEnrollmentGroup = this.state.enrollmentGroups[enrollmentGroup.id]
    if (!storedEnrollmentGroup.__users) {
      this.state.selectedEnrollmentGroups = this._addOrRemove(
        this.state.selectedEnrollmentGroups,
        enrollmentGroup.url,
        true
      )
    } else {
      // Remove enrollment group from selectedEnrollmentGroups, as now selection state for enrollment group is determined by
      // it's users
      this.state.selectedEnrollmentGroups = Im.delete(
        this.state.selectedEnrollmentGroups,
        enrollmentGroup.url
      )
      const users = storedEnrollmentGroup.__users
      this.selectUsersFromEntity(users)
    }
    this.hasChanged()
  },
  onSetTeamSelected(team, val) {
    const storedTeam = this.state.teams[team.id]
    // If users are loaded, use them to determine selection state
    if (storedTeam && storedTeam.__users) {
      return this.onSetSelectedForManyUsers(storedTeam.__users, val)
    }
    this.state.selectedTeams = Im.set(this.state.selectedTeams, team.url, val)
    if (!val) {
      this.state.selectedTeams = Im.delete(this.state.selectedTeams, team.url)
    }
    this.hasChanged()
  },
  onSetEnrollmentGroupSelected(enrollmentGroup, val) {
    const storedEnrollmentGroup = this.state.enrollmentGroups[enrollmentGroup.id]
    // If users are loaded, use them to determine selection state
    if (storedEnrollmentGroup && storedEnrollmentGroup.__users) {
      return this.onSetSelectedForManyUsers(storedEnrollmentGroup.__users, val)
    }
    this.state.selectedEnrollmentGroups = Im.set(
      this.state.selectedEnrollmentGroups,
      enrollmentGroup.url,
      val
    )
    if (!val) {
      this.state.selectedEnrollmentGroups = Im.delete(
        this.state.selectedEnrollmentGroups,
        enrollmentGroup.url
      )
    }
    this.hasChanged()
  },
  onSetSelectedForManyUsers(users, val) {
    let valChanged = false
    users.forEach((user) => {
      if (this.state.selectedUsers[user.url] !== val) {
        valChanged = true
      }
      if (val) {
        this.state.selectedUsers = Im.set(this.state.selectedUsers, user.url, user)
      } else {
        this.state.selectedUsers = Im.delete(this.state.selectedUsers, user.url)
      }
    })
    if (valChanged) this.hasChanged()
  },
  onSetSelectedForManyTeams(teams, val) {
    teams.forEach((team) => {
      this.onSetTeamSelected(team, val)
    })
    this.hasChanged()
  },
  onSetSelectedForManyEnrollmentGroups(enrollmentGroups, val) {
    enrollmentGroups.forEach((enrollmentGroup) => {
      this.onSetEnrollmentGroupSelected(enrollmentGroup, val)
    })
    this.hasChanged()
  },
  onSetUserSearch(val) {
    this.setState({ userSearch: val })
  },
  isSelectedUser(user) {
    return Boolean(this.state.selectedUsers[user.url])
  },
  isSelectedEntity(entity, type) {
    let selectedEntity
    if (type === ENTITY_MAP.ENROLLMENT_GROUP_ENDPOINT) {
      selectedEntity = this.state.selectedEnrollmentGroups
    }
    if (type === ENTITY_MAP.TEAM_ENDPOINT) {
      selectedEntity = this.state.selectedTeams
    }
    const storedEntity = this.state[type][entity.id]
    if (storedEntity && storedEntity.__users) {
      let allSelected = true
      storedEntity.__users.forEach((user) => {
        if (!this.isSelectedUser(user)) allSelected = false
      })
      return allSelected
    }
    return Boolean(selectedEntity[entity.url])
  },
  makeNoTeam() {
    // Team which represents users without a team
    return Im.freeze({
      id: NO_TEAM_ID,
      url: null,
      name: 'No Team',
    })
  },
  isNoTeam(t) {
    return t.id === NO_TEAM_ID
  },
  allEntitiesAreSelected(entities) {
    entities.forEach((entity) => {
      if (anyNotSelected) return
      if (!this.isSelectedEntity(entity)) {
        anyNotSelected = true
      }
    })
    return !anyNotSelected
  },
  allTeamsAreSelected() {
    // TODO - This func is similar to the toggleSelectedUsers method
    let anyNotSelected = false
    _.each(this.state.teams, (team) => {
      if (anyNotSelected) return
      if (!this.isSelectedEntity(team, ENTITY_MAP.TEAM_ENDPOINT)) {
        anyNotSelected = true
      }
    })
    return !anyNotSelected
  },
  allEnrollmentGroupsAreSelected() {
    let anyNotSelected = false
    _.each(this.state.enrollmentGroups, (enrollmentGroup) => {
      if (anyNotSelected) return
      if (!this.isSelectedEntity(enrollmentGroup, ENTITY_MAP.ENROLLMENT_GROUP_ENDPOINT)) {
        anyNotSelected = true
      }
    })
    return !anyNotSelected
  },
  usersAreSelected(users) {
    let anyNotSelected = false
    _.each(users, (user) => {
      if (anyNotSelected) return
      if (!this.isSelectedUser(user)) {
        anyNotSelected = true
      }
    })
    return !anyNotSelected
  },
  // FETCHING
  getTeams(q) {
    return this.fetchEntities(q, 'teams')
  },
  getEnrollmentGroups(q) {
    return this.fetchEntities(q, 'enrollmentGroups')
  },
  fetchEntities(q, type) {
    let store
    if (type === ENTITY_MAP.ENROLLMENT_GROUP_ENDPOINT) {
      store = EnrollmentGroupsState.Store
    }
    if (type === ENTITY_MAP.TEAM_ENDPOINT) {
      store = TeamsState.Store
    }
    const fetch = store.getItems(q)
    if (fetch.result) {
      if (
        type === ENTITY_MAP.TEAM_ENDPOINT &&
        !!fetch.result.length &&
        // Don't add the no team group to team manager results
        !this.state.currentUser.learner.is_learner_group_admin
      ) {
        // Add `noTeam` team (i.e. team for users without a team)
        const noTeam = this.makeNoTeam()
        fetch.result = Im.push(fetch.result, noTeam)
      }
      this.state[type] = Im.produce(this.state[type], (map) => {
        _.each(fetch.result, (entity) => {
          Im.set(map, entity.id, entity)
        })
        return map
      })
    }
    return fetch
  },
  getUsersForTeam(team, enrolledInPlanId) {
    const q = {
      limit: 0,
      ordering: 'first_name',
      is_active: true,
      fields: ['id', 'url', 'first_name', 'last_name', 'learner'],
    }
    if (enrolledInPlanId) {
      q.has_access_to_plan = enrolledInPlanId
    }
    if (this.isNoTeam(team)) {
      q.has_no_team = true
    } else {
      q.learner__learnergroups = team.id
    }
    const fetch = UsersState.Store.getItems(q)
    this.userFetchPromise(fetch, 'teams', team)
    return fetch
  },
  getUsersForEnrollmentGroup(enrollmentGroup, currentUser, enrolledInPlanId) {
    const learner = currentUser.learner
    const q = {
      limit: 0,
      is_active: true,
      ordering: 'first_name',
    }
    if (enrolledInPlanId) {
      q.has_access_to_plan = enrolledInPlanId
    }
    if (!learner.is_company_admin && !learner.is_area_manager && learner.is_learner_group_admin) {
      _.extend(q, {
        learner__learnergroups: getIdFromApiUrl(learner.learner_group),
      })
    }

    q.enrollment_groups = enrollmentGroup.id

    const fetch = UsersState.Store.getItems(q)
    this.userFetchPromise(fetch, 'enrollmentGroups', enrollmentGroup)
    return fetch
  },
  userFetchPromise(fetch, endpoint, entity) {
    // shared logic between fetch for users in team / enrollment group
    let selectedEntities
    if (endpoint === ENTITY_MAP.ENROLLMENT_GROUP_ENDPOINT) {
      selectedEntities = this.state.selectedEnrollmentGroups
    }
    if (endpoint === ENTITY_MAP.TEAM_ENDPOINT) {
      selectedEntities = this.state.selectedTeams
    }
    fetch.toPromise().then((results) => {
      let storedEntity = this.state[endpoint][entity.id]
      storedEntity = Im.set(storedEntity, '__users', results)
      this.state[endpoint] = Im.set(this.state[endpoint], storedEntity.id, storedEntity)
      if (this.state.selectedEnrollmentGroups[entity.url]) {
        this.onSetSelectedForManyUsers(storedEntity.__users, true)
      }
      // Remove team from selectedEnrollmentGroups, as now selection state for
      // enrollment group is determined by it's users
      selectedEntities = Im.delete(selectedEntities, entity.url)
    })
  },
  // RETRIEVAL
  getSelectedUsers() {
    return this.state.selectedUsers
  },
  getSelectedUserURLs() {
    return _.keys(this.state.selectedUsers)
  },
  getSelectedLearnerURLs() {
    return _.values(this.state.selectedUsers).map((user) => user.learner)
  },
  getSelectedTeamURLs() {
    return _.keys(this.state.selectedTeams)
  },
  getSelectedEnrollmentGroupURLs() {
    return _.keys(this.state.selectedEnrollmentGroups)
  },
  getUsersFromSelectedTeams() {
    // to avoid creating another endpoint that accepts teams, instead create
    // an array of all users in selected teams.
    const teams = this.state.teams
    const selectedTeams = this.getSelectedTeamURLs()
    const teamIds = selectedTeams.map((team) => parseInt(getIdFromApiUrl(team)))
    const teamObjs = teamIds.map((id) => teams[id])
    const members = []
    teamObjs.forEach((team) => {
      const teamMembers = team.members
      teamMembers.forEach((member) => {
        members.push(member.user)
      })
    })
    return members
  },
  getUserSearch() {
    return this.state.userSearch
  },
})

app.register('ManyUserSelectionStore', ManyUserSelectionStore)
app.register('ManyUserSelectionActionCreators', ManyUserSelectionActionCreators)
const ActionCreators = app.ManyUserSelectionActionCreators
const Store = app.ManyUserSelectionStore

export default {
  Constants,
  ActionCreators,
  Store,
}
